import ConfirmRegisterFeature from '../../components/features/auth/ConfirmRegisterFeatureA';

const ConfirmRegister = () => (
  <>
    <div className="flex flex-col gap-10 h-full">
      <ConfirmRegisterFeature />
    </div>
  </>
);

export default ConfirmRegister;
