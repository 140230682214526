import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  notification,
  Result,
  Typography
} from 'antd';
import SeeWardLogo from '../../../assets/image/logoSeeWard.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import userService from '../../../service/user.service';
import Loading from '../../UI/loading/Loading';

const ConfirmRegisterFeature = () => {
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ButtonisLoading, setButtonIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      userService
        .tokenCheck(token)
        .then((res: any) => {
          console.log(res);
          if (res.success === false) {
            setTokenIsValid(false);
          } else {
            form.setFieldsValue({ email: res.data.email });
            setTokenIsValid(true);
          }
        })
        .catch((err) => {
          setTokenIsValid(false);
        }).finally(() => {
          setIsLoading(false);
        });
    } else {
      setTokenIsValid(false);
    }
    setIsLoading(false);
  }, [form]);

  const handleFormChange = () => {
    const { firstName, lastName, password, password2 } = form.getFieldsValue();
    if (firstName && lastName && password && password2) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleCreateAccount = async (values: any) => {
    try {
      setButtonIsLoading(true);
      const res = await userService.confirmRegisterAccount(
        new URLSearchParams(window.location.search).get('token')!,
        values.email,
        values.firstName,
        values.lastName,
        values.password,
        values.password2
      );
      if (res.success) {
        notification.success({
          message: 'Account Created',
          description: 'Your account has been created successfully.'
        });
        // Redirect to login or dashboard after creation.
        navigate('/login');
        form.resetFields();
      } else {
        notification.error({
          message: 'Creation Failed',
          description: 'There was an error creating your account. Please try again.'
        });
      }
      setIsButtonDisabled(true);
    } catch (error) {
      notification.error({
        message: 'Creation Failed',
        description: 'There was an error creating your account. Please try again.'
      });
    } finally {
      setButtonIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center my-20">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <a href="/">
          <img
            alt="SeeWard"
            src={SeeWardLogo}
            className="mx-auto h-10 w-auto"
          />
        </a>
      </div>
      {isLoading && <Loading />}
      {tokenIsValid === false && isLoading === false &&
        <div className="flex justify-center mt-10">
          <Card className='max-w-[600px]'>
            <Result
              status="error"
              title="The provided token is invalid or has expired."
              subTitle="Please check your email and try again."
              extra={[
                <Button
                  type="primary"
                  key="console"
                  onClick={() => navigate('/')}
                >
                  Go to Home
                </Button>, <Button
                  key="buy"
                  onClick={() => navigate('/contact')}
                >
                  Contact Support
                </Button>
              ]}
            >
            </Result>
          </Card>
        </div>
      }
      {tokenIsValid && !isLoading &&
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-10 pt-10 sm:px-12">
              <Typography.Title
                level={4}
              >Create your account</Typography.Title>
              <div className="mt-5" />
              <Alert
                type="info"
                showIcon
                message={
                  <div className="text-[13px]">
                    You have been invited by <b>moumniabdou.adil@gmail.com</b> to integrate his
                    business on Seeward. Create an account to log in to your new Dashboard.
                  </div>
                }
              />
              <div className="mb-10" />
              <Form
                form={form}
                name="confirmRegistration"
                autoComplete="off"
                layout="vertical"
                onValuesChange={handleFormChange}
                onFinish={handleCreateAccount}
              >
                <Form.Item label="Email" name="email">
                  <Input size="large" disabled />
                </Form.Item>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[{
                    required: true,
                    message: 'Please input your first name'
                  }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[{
                    required: true,
                    message: 'Please input your last name'
                  }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{
                    required: true,
                    message: 'Please input your password'
                  }]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="password2"
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password'
                    }, ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords do not match!'));
                      }
                    })
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-full"
                    size="large"
                    loading={ButtonisLoading}
                    disabled={isButtonDisabled}
                  >
                    Create an account
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="mx-2 pb-2 pt-5">
              <div
                className="h-[50px] w-full bg-gray rounded-lg flex justify-center items-center gap-2 text-black-2"
              >
                Already have an account?{' '}
                <Typography.Link href="/login">Log in now.</Typography.Link>
              </div>
            </div>
          </div>
        </div>}

    </div>
  );
};

export default ConfirmRegisterFeature;
