import logo from '../../../assets/image/logoSeeWardWhite.png';
import logoSmall from '../../../assets/image/white.png';
import profile from '../../../assets/image/empty-user.jpg';
import {
  ChevronFirst,
  ChevronLast,
  MoreVertical,
  Settings,
  UserPen,
  LogOut,
  LayoutDashboard,
  StickyNote,
  Bug,
  Server,
  Flag,
  Link as LinkIcon,
  UsersRound,
  Bell,
  Route,
  Skull,
  ArrowDown,
  Layers,
  Plus,
  Check,
  Star,
  StarOff
} from 'lucide-react';
import React, {
  createContext,
  useContext,
  useState
} from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Dropdown,
  MenuProps,
  Modal,
  Space,
  Table,
  Tabs
} from 'antd';
import { logout as logoutAction } from '../../../redux/auth/actions';
import CVE from '../../../assets/icons/cve.svg';
import { selectAuth } from '../../../redux/auth/selectors';
import { Input } from 'antd';
import { DataType } from '../../features/asset/create/tab/Addvulns/Type';
import WorkSpacesButton from './WorkSpace';
import { usePermission2 } from '../../hooks/CheckPermissions';

const { Search } = Input;

const SidebarContext = createContext({ expanded: false });

export default function Sidebar2() {
  const [expanded, setExpanded] = useState(
    localStorage.getItem('sidebarCollapsed') !== null
      ? JSON.parse(localStorage.getItem('sidebarCollapsed')!)
      : true
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { current } = useSelector(selectAuth);
  const userEmail = current.email;
  const username = `${current.first_name || ''} ${current.last_name || ''}`;
  const is_admin = current?.default_workspace?.roles?.some((e: any) => e.role.toLowerCase() == 'admin');
  const has_eol_view = usePermission2('eol.view');
  const has_cve_view = usePermission2('cve.view');
  const has_report_view = usePermission2('report.view');
  const has_project_view = usePermission2('project.view');
  const has_action_plan_view = usePermission2('action_plan.view');
  const has_connector_view = usePermission2('connector.admin');

  // const has_eol_view = current?.permissions?.some((e: any) => e == 'eol.view');
  // const has_cve_view = current?.permissions?.some((e: any) => e == 'cve.view');

  const handleToggle = () => {
    setExpanded(!expanded);
    localStorage.setItem('sidebarCollapsed', JSON.stringify(!expanded));
  };

  const logoutHandler = () => {
    Modal.confirm({
      title: 'Are you sure you want to log out?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        await asyncLogout(); // Await the logout completion
        navigate('/login'); // Navigate only after successful logout
      }
    });
  };

  async function asyncLogout() {
    try {
      await dispatch<any>(logoutAction());
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }

  const menuItems = [
    {
      key: '1',
      icon: <Bell size={15} />,
      text: 'Notification',
      link: '/notification',
      alert: true
    }, {
      type: 'divider',
      label: 'Menu'
    }, {
      key: '1',
      icon: <LayoutDashboard size={15} />,
      text: 'Dashboard',
      link: '/'
    }, {
      key: '1',
      icon: <Route size={15} />,
      text: 'Action plan',
      link: '/action-plan',
      hidden: !has_action_plan_view
    }, {
      key: '2',
      icon: <StickyNote size={15} />,
      text: 'Projects',
      link: '/project',
      hidden: !has_project_view
    }, {
      key: '3',
      icon: <Bug size={15} />,
      text: 'Vulnerability',
      link: '/vulnerability',
      subPaths: ['/vulnerability/create', '/vulnerability/edit', '/vulnerability/detail']
    }, {
      key: '4',
      icon: <Server size={15} />,
      text: 'Asset',
      link: '/asset'
    }, {
      key: '5',
      icon: <Flag size={15} />,
      text: 'Reporting',
      link: '/report',
      hidden: !has_report_view
    }, {
      key: '6',
      icon: <LinkIcon size={15} />,
      text: 'Connector',
      link: '/connectors',
      hidden: !has_connector_view
    }, {
      type: 'divider',
      label: 'Advanced',
      hidden: !is_admin && !has_cve_view && !has_eol_view
    }, {
      key: '7',
      icon: <img src={CVE} width={20} />,
      text: 'CVE',
      link: '/vulnerability/cve',
      hidden: !has_cve_view
    }, {
      key: '4',
      icon: <Skull size={15} />,
      text: 'Product EOL',
      link: '/product-eol',
      hidden: !has_eol_view
    }, {
      key: '8',
      icon: <Settings size={15} />,
      text: 'Settings',
      link: '/settings',
      hidden: !is_admin
    }
  ];

  const [workspace, setworkspace] = useState('Workspace 1');

  const dropdownItems: any = [
    {
      key: '1',
      label: (
        <Link
          to="/profile"
          className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
        >
          <div className="flex">
            {/* <img src={profile} className="w-10 h-10 rounded-md" /> */}
            <img
              src={`/assets/avatar/${userEmail?.charAt(userEmail.length - 1)}.svg`}
              alt="avatar"
              className='h-10 w-10 bg-[#b6e3f4] p-1 rounded-md'
            />
            <div
              className="flex justify-between items-center overflow-hidden transition-all w-52 ml-3"
            >
              <div className="leading-4">
                <h4
                  className="font-semibold text-black"
                >{username}</h4>
                <span
                  className="text-xs text-black"
                >{userEmail}</span>
              </div>
            </div>
          </div>
        </Link>
      )
    }, { type: 'divider' }, {
      key: '2',
      label: (
        <Link
          to="/profile"
          className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
        >
          <UserPen width={18} />
          My Profile
        </Link>
      )
    }, {
      key: '3',
      label: (
        <Link
          to="/settings"
          className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
        >
          <Settings width={18} />
          Account Settings
        </Link>
      ),
      disabled: !is_admin
    }, { type: 'divider' }, {
      key: '4',
      onClick: logoutHandler,
      label: (
        <button
          className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
        >
          <LogOut width={18} />
          Log Out
        </button>
      )
    }
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  interface DataType {
    key: React.Key;
    name: string;
    id: string;
  }

  const columns: any = [
    {
      title: '',
      dataIndex: '',
      width: 80,
      render: (text: any, record: any, i: any) => <div
        className='flex justify-end items-center gap-2'
      >
        {i == 1 ? <Check
          size={14}
          color='blue'
        /> : <div
          className='h-3'
        ></div>}
        {i == 2 ? <Star size={13} /> : <StarOff size={13} />}
        <Layers size={13} />
      </div>
    }, {
      title: 'Name',
      dataIndex: 'name',
      width: 300,
      render: (text: any) => <div
        onClick={() => {
          handleCancel();
          setworkspace(text);
        }}
      >
        <a className='text-blue-700 underline'>{text}</a>
      </div>
    }, {
      title: 'Id',
      dataIndex: 'id'
    }
  ];

  const dataSource = Array.from({ length: 100 }).map<DataType>((_, i) => ({
    key: i,
    name: `Workspace ${i}`,
    id: `workspace-${i}`
  }));

  return (
    <>
      <aside className="h-screen bg-[#181818]">
        <nav className="h-full flex flex-col shadow-sm">
          {!expanded ? (
            <div>
              <img
                src={logoSmall}
                className="overflow-hidden transition-all w-8 m-4 -mb-2 mr-2"
              />
            </div>
          ) : (
            <div />
          )}
          <div
            className="p-4 pb-2 flex justify-between items-center"
          >
            <img
              src={logo}
              className={`overflow-hidden transition-all ${expanded ? 'w-34' : 'w-0'}`}
            />
            <button
              onClick={() => handleToggle()}
              className="p-1.5 rounded-lg text-white bg-[#272b31] hover:bg-[#33383e]"
            >
              {expanded ? <ChevronFirst /> : <ChevronLast />}
            </button>
          </div>

          <WorkSpacesButton expanded={expanded} />

          <SidebarContext.Provider value={{ expanded }}>
            <ul className="flex-1 px-3 mt-5">
              {menuItems.map((item, index) => {
                if (item.type === 'divider' && !item.hidden) {
                  return (
                    <div key={index + '-divider'}>
                      <Divider
                        className='bg-[#25272b]'
                      />
                      <div
                        className={`mb-2 ${expanded ? 'block' : 'hidden'}`}
                      >
                        <span
                          className='text-[#a3a5a6] uppercase'
                        >{item.label}</span>
                      </div>
                    </div>
                  );
                }
                if (item.hidden) return;
                return (
                  <SidebarItem
                    key={index + '-item'}
                    icon={item.icon}
                    text={item.text}
                    link={item.link}
                    alert={item.alert}
                    // disabled={item.disabled?}
                    active={
                      location.pathname === item.link ||
                      (item.subPaths && item.subPaths.some((path) => location.pathname.startsWith(path)))
                    }
                    navigate={navigate}
                  />
                );
              })}
            </ul>
          </SidebarContext.Provider>
          <Dropdown
            menu={{ items: dropdownItems }}
            placement="topRight"
          >
            <div
              className="border-t border-[#25272b] flex p-3 cursor-pointer"
              onClick={(e) => e.preventDefault()}
            >
              <img
                src={`/assets/avatar/${userEmail?.charAt(userEmail.length - 1)}.svg`}
                alt="avatar"
                className='h-10 w-10 bg-[#b6e3f4] p-1 rounded-md'
              />
              <div
                className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}`}
              >
                <div className="leading-4">
                  <h4
                    className="font-semibold text-white"
                  >{username}</h4>
                  <span
                    className="text-xs text-[#a3a5a6]"
                  >{userEmail}</span>
                </div>
                <MoreVertical
                  size={20}
                  className="text-white"
                />
              </div>
            </div>
          </Dropdown>
        </nav>
      </aside>
    </>
  );
}
export function SidebarItem({ icon, text, link, active, alert, navigate, disabled }: any) {
  const { expanded } = useContext(SidebarContext);

  const handleClick = () => {
    if (!disabled) {
      navigate(link);
    }
  };

  return (

    <div className="flex items-center">
      <li
        onClick={handleClick}
        className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md transition-colors group ${disabled
          ? 'cursor-not-allowed text-[#6e6f6f]'
          : active
            ? 'text-white bg-[#272b31] cursor-pointer'
            : 'hover:text-white text-[#a3a5a6] cursor-pointer'
        }`}
      >
        {icon}
        <span
          className={`overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}`}
        >
          {text}
        </span>
        {alert && (
          <div
            className={`absolute right-2 w-2 h-2 rounded bg-red-600 ${expanded ? '' : 'top-2'}`}
          ></div>
        )}

        {!expanded && (
          <div
            className={`z-99999 absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 ${disabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {text}
          </div>
        )}
      </li>
    </div>
  );
}
