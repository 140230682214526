import { createSelector } from 'reselect';
const authSelect = (state) => state.auth;

export const selectAuth = createSelector([authSelect], (auth) => auth);
export const selectCurrentWorkspace = createSelector([authSelect], (auth) => auth.current?.default_workspace);
export const selectCurrentAdmin = createSelector([authSelect], (auth) => auth.current);
export const permissionUserInWorkspace = createSelector([authSelect], (auth) => auth.current?.permissions);

export const selectCurrentEmail = createSelector([authSelect], (auth) => auth.current?.email);

export const isLoggedIn = createSelector([authSelect], (auth) => auth.isLoggedIn);

export const selectUserRoles = createSelector([authSelect], (auth) => auth.current?.roles || []);
export const selectIsLoggedIn = createSelector([authSelect], (auth) => auth.isLoggedIn);
