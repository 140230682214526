import {
  Button,
  Dropdown,
  Form,
  Input,
  notification,
  Space
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ArrowLeft } from 'lucide-react';
import SeeWardLogo from '../../../assets/image/logoSeeWard.png';
import { useSelector } from 'react-redux';
import {
  selectCurrentAdmin,
  selectCurrentEmail
} from '../../../redux/auth/selectors';
import workspaceService from '../../../service/workspace.service';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

const CreateWorkspaceFeature = () => {
  const email = useSelector(selectCurrentEmail);
  const currentUser = useSelector(selectCurrentAdmin);
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  const handleCreateWorkspace = async (values: any) => {
    try {
      const res = await workspaceService.createWorkspace(values.workspaceName, values.description);
      if (res.success !== false) {
        const res2 = await workspaceService.selectWorkspace(res.id);
        if (res2.success !== false) {
          window.location.href = '/';
          form.resetFields();
        }
      }
      setIsButtonDisabled(true);
    } catch (error) {
      notification.error({
        message: 'Creation Failed',
        description: 'There was an error creating the workspace. Please try again.'
      });
    }
  };

  const handleFormChange = () => {
    const { workspaceName, description } = form.getFieldsValue();
    setIsButtonDisabled(!workspaceName);
  };

  return (
    <>
      <div className="flex justify-between items-center mt-5 mx-5">
        <Button
          type="default"
          href="/"
          icon={<ArrowLeft className="mt-1" size={17} />}
        >
          Back to SeeWard
        </Button>
        <div className="hover:bg-blue-gray-50 pt-2 px-5 rounded-md">
          <Dropdown menu={{ items: [] }}>
            <a onClick={(e) => e.preventDefault()}>
              <div className="font-light text-sm">Logged in as:</div>
              <Space>
                {email}
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>

      <div
        className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8"
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            alt="SeeWard"
            src={SeeWardLogo}
            className="mx-auto h-10 w-auto"
          />
          <h2
            className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900"
          >
            Create a New Workspace
          </h2>
          <p className="text-center">
            Workspaces are shared environments where teams can work on projects, vulnerabilities, and issues.
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <Form
              form={form}
              layout="vertical"
              onFinish={handleCreateWorkspace}
              onFieldsChange={handleFormChange}
            >
              <Form.Item
                label="Workspace Name"
                name="workspaceName"
                rules={[{
                  required: true,
                  message: 'Please enter the workspace name'
                }]}
              >
                <Input 
                  size="large" 
                  placeholder="Enter workspace name" 
                  disabled={!currentUser.admin_kc} 
                />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
              >
                <TextArea
                  disabled={!currentUser.admin_kc}
                  size="large"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="Enter workspace description"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-full"
                  size="large"
                  disabled={isButtonDisabled || !currentUser.admin_kc}
                >
                  Create Workspace
                </Button>
              </Form.Item>
              {!currentUser.admin_kc && <p
                className="text-center text-red-500"
              >You are not allowed to create a workspace.</p>}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateWorkspaceFeature;
