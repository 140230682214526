import { useSelector } from 'react-redux';
import { permissionUserInWorkspace } from '../../redux/auth/selectors';

const usePermission = () => {
  const permissions = useSelector(permissionUserInWorkspace);
  console.log(permissions);
  return (permission: string) => permissions.includes(permission);
};

export function usePermission2(permission: string): boolean {
  const permissions = useSelector(permissionUserInWorkspace);
  return permissions?.includes(permission) || false;
}

export default usePermission;