import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import DefaultLayout from './components/layout/DefaultLayout';
import LoginPage from './pages/login/LoginPage';
import RoleProtectedRoute from './RoleProtectedRoute';
import routes from './routes';
import CreateWorkspace from './pages/workspaces/createWorkspace';
import ListWorkspace from './pages/workspaces/createWorkspace copy';
import ConfirmRegister from './pages/auth/ConfirmRegister';
import Reset from './pages/auth/Reset';
import ResetPassword from './pages/auth/ResetPassword';

function AppContent() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/auth/complete-registration"
        element={<ConfirmRegister />}
      />
      <Route
        path="/auth/reset"
        element={<Reset />}
      />
      <Route
        path="/auth/reset-password"
        element={<ResetPassword />}
      />
      <Route element={<DefaultLayout />}>
        {routes.map((r, i) => (
          <Route
            key={i}
            path={r.path}
            element={
              <RoleProtectedRoute requiredRoles={r.requiredRoles}>
                <Suspense fallback="loading">
                  <r.component />
                </Suspense>
              </RoleProtectedRoute>
            }
          />
        ))}
      </Route>
      <Route>
        <Route
          path="/workspace/create"
          element={
            <RoleProtectedRoute>
              <Suspense fallback="loading">
                <CreateWorkspace />
              </Suspense>
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/workspaces"
          element={
            <RoleProtectedRoute>
              <Suspense fallback="loading">
                <ListWorkspace />
              </Suspense>
            </RoleProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

export default AppContent;
