import {
  Alert,
  Button,
  Form,
  Input,
  notification,
  Typography
} from 'antd';
import { useSelector } from 'react-redux';
import { selectCurrentEmail } from '../../../redux/auth/selectors';
import SeeWardLogo from '../../../assets/image/logoSeeWard.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import userService from '../../../service/user.service';
import * as Yup from 'yup';
import { LockOutlined } from '@ant-design/icons';
import { Link } from 'lucide-react';
const ResetFeature = () => {
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [ButtonisLoading, setButtonIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleFormChange = () => {
    const { email } = form.getFieldsValue();
    Yup.string().email('Invalid email address').required('Email is required');
    const emailSchema = Yup.string().email('Invalid email address');
    emailSchema
      .validate(email.trim())
      .then(() => {
        setIsButtonDisabled(false);
      })
      .catch(() => {
        setIsButtonDisabled(true);
      });
  };

  const [isSuccess, setIsSuccess] = useState(false);

  const handleCreateAccount = async (values: any) => {
    try {
      setButtonIsLoading(true);
      const res = await userService.resetInvitation(
        values.email
      );
      if (res.success) {
        setIsSuccess(true);
      } else {
        notification.error({
          message: 'Creation Failed',
          description: 'There was an error creating your account. Please try again.'
        });
      }
      setIsButtonDisabled(true);
    } catch (error) {
      notification.error({
        message: 'Creation Failed',
        description: 'There was an error creating your account. Please try again.'
      });
    } finally {
      setButtonIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center my-20">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <a href="/">
          <img
            alt="SeeWard"
            src={SeeWardLogo}
            className="mx-auto h-10 w-auto"
          />
        </a>
      </div>
      {
        <div>
          {isSuccess === true &&
            <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]'>
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-10 py-10 sm:px-12">
                  <Typography.Title level={4}>
                    Check your email
                  </Typography.Title>
                  <Typography.Paragraph className='text-sm'>
                    Thanks ! If the email address <strong>{form.getFieldValue('email')}</strong> is in our records, you will receive an email with instructions on how to reset your password.
                  </Typography.Paragraph>
                  <Typography.Paragraph className='text-sm'>
                    If you have not received an email within 5 minutes, check your spam folder, request a
                    <Typography.Link
                      className='mr-1'
                      onClick={() => {
                        handleCreateAccount({
                          email: form.getFieldValue('email')
                        });
                      }}
                    > new email</Typography.Link>
                    or
                    <Typography.Link
                      onClick={() => setIsSuccess(false)}
                      className='ml-1'
                    >
                      try again with another email address
                    </Typography.Link>
                  </Typography.Paragraph>
                </div>
              </div>
            </div>}
          {!isSuccess &&
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-10 pt-10 sm:px-12">
                  <Typography.Title
                    level={4}
                  >Réinitialisation du mot de passe
                  </Typography.Title>
                  <div className="mt-5" />
                  <Alert
                    type="info"
                    showIcon
                    message={
                      <div
                        className="text-[13px]"
                      >Enter the email address associated with your account and we'll send you a link to reset your password.
                      </div>
                    }
                  />
                  <div className="mb-10" />
                  <Form
                    form={form}
                    name="confirmRegistration"
                    autoComplete="off"
                    layout="vertical"
                    onValuesChange={handleFormChange}
                    onFinish={handleCreateAccount}
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your email'
                        }
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="w-full"
                        size="large"
                        loading={ButtonisLoading}
                        disabled={isButtonDisabled}
                      >
                        Create an account
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="mx-2 pb-2 pt-5">
                  <div
                    className="h-[50px] w-full bg-gray rounded-lg flex justify-center items-center gap-2 text-black-2"
                  >
                    Already have an account?{' '}
                    <Typography.Link
                      href="/login"
                    >Log in now.</Typography.Link>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }

      <div
        className='flex justify-center items-center'
      >
        <Typography.Paragraph
          type='secondary'
          className='max-w-[440px] text-sm mt-10'
        >
          <LockOutlined className='mr-2' />
          Ne communiquez jamais vos identifiants Seeward. Si plusieurs personnes doivent accéder à votre compte, invitez-les en tant que membres de votre équipe dans les paramètres.
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export default ResetFeature;
