import SeeWardLogo from '../../../assets/image/logoSeeWard.png';
import { useEffect, useState } from 'react';
import workspaceService from '../../../service/workspace.service';
import Loading from '../../UI/loading/Loading';
import {
  Bug,
  Plus,
  Server,
  UsersRound
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentAdmin,
  selectCurrentEmail
} from '../../../redux/auth/selectors';
import { Dropdown, Input, Space } from 'antd';
import { logout } from '../../../redux/auth/actions';
import { LogoutOutlined } from '@ant-design/icons';

const { Search } = Input;

export default function Example() {
  const email = useSelector(selectCurrentEmail);
  const currentUser = useSelector(selectCurrentAdmin);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',  // Jan
      day: 'numeric',  // 12
      year: 'numeric', // 2025
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);

  const fetchWorkspaces = async (query = '') => {
    try {
      setLoading(true);
      const res = await workspaceService.getWorkspaceList(query);
      setDataSource(res.workspaces || []);
    } catch (error) {
      console.error('Failed to fetch workspaces:', error);
    } finally {
      setLoading(false);
    }
  };

  // Initial Load
  useEffect(() => {
    fetchWorkspaces();
  }, []);

  const navigate = useNavigate();
  const onSelectWorkspace = async (workspaceId: string) => {
    try {
      const res = await workspaceService.selectWorkspace(workspaceId);
      if (res.success !== false) {
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Failed to select workspace:', error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (value: string) => {
    fetchWorkspaces(value);
  };

  const dispatch = useDispatch();
  const onLogout = async () => {
    await asyncLogout();
    navigate('/login');
  };

  async function asyncLogout() {
    try {
      await dispatch<any>(logout());
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }

  return (
    <div className="">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          aria-label="Global"
          className="flex items-center justify-between p-6 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">SeeWard</span>
              <img
                alt=""
                src={SeeWardLogo}
                className="h-10 w-auto"
              />
            </a>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <div className="hover:bg-blue-gray-50 pt-2 px-5 rounded-md">
              <Dropdown
                menu={{
                  items: [{
                    key: '1',
                    onClick: onLogout,
                    label: (
                      <div>
                        <LogoutOutlined /> Logout
                      </div>
                    )
                  }]
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <div className="font-light text-sm">Logged in as:</div>
                  <Space>
                    {email}
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        </nav>
      </header>

      <div className="relative isolate px-6 pt-0 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-30 ">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center ">
            {/* <div
              className="relative rounded-full px-3 py-1 text-sm/6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
            >
              Announcing our next round of funding.{' '}
              <a href="#" className="font-semibold text-indigo-600">
                <span aria-hidden="true" className="absolute inset-0" />
                Read more <span aria-hidden="true">&rarr;</span>
              </a>
            </div> */}
          </div>
          <div className="text-center">
            <h1
              className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl"
            >
              Welcome to Seeward
            </h1>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="">
          <div
            className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-0 py-8 lg:max-w-[1200px] lg:px-8 rounded-2xl"
          >
            <div className="flex items-center justify-between space-x-4">
              <h2
                className="text-2xl font-medium text-gray-900"
              >Workspaces</h2>
              <div
                className="max-w-xl w-full"
              >
                <Search
                  placeholder="Search Workspace"
                  loading={false}
                  allowClear
                  size='large'
                  onSearch={onSearch}
                />
              </div>
            </div>
            {loading && <div className='bg-transparent'>
              <div
                className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"
              ></div>
            </div>}
            {!loading && <div
              className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3"
            >
              {currentUser?.admin_kc && (
                <div
                  onClick={() => navigate('/workspace/create')}
                  className="min-h-[200px] bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-2xl p-1 hover:opacity-75"
                >
                  <span
                    className="flex flex-col justify-center text-primary text-2xl font-medium items-center w-full h-full rounded-xl p-2 bg-white hover:shadow-lg cursor-pointer"
                  >
                    <Plus className="w-12 h-12 mb-5" />
                    <div>Create Workspace</div>
                  </span>
                </div>
              )}
              {dataSource.map((product: any, index: number) => <div
                key={index}
                onClick={() => onSelectWorkspace(product.id)}
                className="overflow-hidden flex flex-col justify-between rounded-xl bg-white shadow hover:shadow-lg cursor-pointer"
              >
                <div
                  className="px-4 py-20 sm:p-4 mb-20"
                >
                  <div className="text-lg font-semibold">
                    {product.name}
                  </div>
                  <div className="text-sm text-[#6B7280] min-h-10">
                    {product.description}
                  </div>

                </div>
                <div className="px-4 py-4 sm:px-6">
                  {/* ICONS */}
                  <div className="flex gap-3">
                    <div
                      className='flex items-center text-[#6B7280] gap-1'
                    >
                      {product.user_count}
                      <UsersRound className='w-4 ' />
                    </div>
                    <div
                      className='flex items-center text-[#6B7280] gap-1'
                    >
                      {product.vuln_count}
                      <Bug className='w-4 ' />
                    </div>
                    <div
                      className='flex items-center text-[#6B7280] gap-1'
                    >
                      {product.asset_count}
                      <Server className='w-4 ' />
                    </div>
                  </div>
                  <span
                    className='text-sm text-[#6B7280]'
                  >{formatDate(product.created_at)}</span>
                </div>
              </div>
              )}
            </div>
            }
          </div>
        </div>
      </div>

    </div>
  );
}
