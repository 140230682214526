import { Typography } from 'antd';
import DashboardGeneral from '../../components/features/dashboard';
import { usePermission2 } from '../../components/hooks/CheckPermissions';

const Dashboard = () => {

  const hasPermission = usePermission2('dashboard.view');

  if (!hasPermission) {
    return (
      <div className='flex flex-col justify-center mt-20 text-center'>
        <h2
          className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-4xl"
        >
                    Welcome to Seeward
        </h2>
      </div>
    );
  }

  return <DashboardGeneral />;
};

export default Dashboard;
