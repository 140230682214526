import CreateWorkspaceFeature from '../../components/features/workspaces/CreateWorkspaceFeature';

const CreateWorkspace = () => (
  <>
    <div className="flex flex-col gap-10 h-full">
      <CreateWorkspaceFeature />
    </div>
  </>
);

export default CreateWorkspace;
