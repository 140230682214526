import { notification } from 'antd';
import codeMessage from './codeMessage';

const errorHandler = (error) => {
  if (error?.response) {
    const { data, status } = error.response;
    const errorText = data?.message || codeMessage[status];
    notification.config({
      duration: 10,
      maxCount: 2
    });
    notification.error({
      message: 'Request error',
      description: errorText
    });
    return {
      success: false,
      data: data,
      message: errorText
    };
  } else {
    notification.config({
      duration: 5,
      maxCount: 1
    });
    if (navigator.onLine) {
      notification.error({
        message: 'Problem connecting to server',
        description: 'Cannot connect to the server, try again later'
      });
      return {
        success: false,
        data: null,
        message: 'Cannot connect to the server, check your internet network'
      };
    } else {
      notification.error({
        message: 'No internet connection',
        description: 'Cannot connect to the Internet, check your network'
      });
      return {
        success: false,
        data: null,
        message: 'Cannot connect to the server, check your internet network'
      };
    }
  }
};

export default errorHandler;
