import ResetPasswordFeature from '../../components/features/auth/ResetPassword';

const ResetPassword = () => (
  <>
    <div className="flex flex-col gap-10 h-full">
      <ResetPasswordFeature />
    </div>
  </>
);

export default ResetPassword;
