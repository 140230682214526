import { API_BASE_URL, AUTH_BASE_URL } from '../../config/serverApiConfig';

import axios from 'axios';
import errorHandler from '../../request/errorHandler';
import successHandler from '../../request/successHandler';
import CryptoJS from 'crypto-js';

export const login = async ({ email, password }: { email: string; password: string }) => {
  try {

    const secretKey = '4A7F9E2D5C8B3A6F1E0D9C8B3A6F1E0D9C8B3A6F1E0D9C8B3A6F1E0D9C8B3A6F';

    function encryptPassword(password: string) {
      // Convert the secret key from hex to a WordArray
      const key = CryptoJS.enc.Hex.parse(secretKey);
      // Generate a random IV (16 bytes)
      const iv = CryptoJS.lib.WordArray.random(16);
      // Encrypt the password using AES-256-CBC
      const encrypted = CryptoJS.AES.encrypt(password, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      // Combine the IV and ciphertext into a single Base64-encoded string
      return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
    }

    const encrypted = encryptPassword(password);
    console.log(encrypted);
    const response = await axios.post(
      AUTH_BASE_URL + 'login',
      { email, password: encrypted }
    );

    const { status, data } = response;
    response.data = data;
    successHandler(
      { response: response.data, status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return { status, data };
  } catch (error) {
    console.log('Errorrr , ', error);
    return errorHandler(error);
  }
};

export const logout = async () => {
  const user = window.localStorage.getItem('auth');
  const tmpAuth = user ? JSON.parse(user).current : null;

  if (!tmpAuth) {
    console.warn('No user session found to log out.');
    return; // Exit if no user session exists
  }

  try {
    // Make an API call to log out if needed
    const response = await axios.delete(
      AUTH_BASE_URL + 'logout', {
        headers: {
          Authorization: `Bearer ${tmpAuth.access_token}`
        }
      });

    if (!response || !response.data) {
      throw new Error('Logout API response is invalid.');
    }
  } catch (error: any) {
    console.error('Error during logout:', error.message || error);
    throw error;
  }
};

export const getMe = async () => {
  try {
    const user = window.localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : null;
    const response = await axios.get(API_BASE_URL + 'me/details', {
      headers: {
        Authorization: `Bearer ${tmpAuth.access_token}`
      }
    });
    if (!response || !response.data) {
      throw new Error('Get me API response is invalid.');
    }
    return {
      data: response.data,
      status: 200
    };
  } catch (error) {
    return errorHandler(error);
  }
};