import ResetFeature from '../../components/features/auth/Reset';

const Reset = () => (
  <>
    <div className="flex flex-col gap-10 h-full">
      <ResetFeature />
    </div>
  </>
);

export default Reset;
