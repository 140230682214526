import { notification } from 'antd';
import codeMessage from './codeMessage';

const successHandler = (
  response,
  options = {
    notifyOnSuccess: false,
    notifyOnFailed: true
  }
) => {
  const { data, status } = response;

  // Check for success status codes
  if (status >= 200 && status < 300) {
    const successText = data?.message || codeMessage[status];
    if (options.notifyOnSuccess) {
      notification.config({
        duration: 5,
        maxCount: 5
      });
      notification.success({
        message: 'Request success',
        description: successText
      });
    }
  } else {
    // For non-success status codes, treat it as an error notification
    const errorText = data?.message || codeMessage[status];
    if (options.notifyOnFailed) {
      notification.config({
        duration: 4,
        maxCount: 2
      });
      notification.error({
        message: `Request error ${status}`,
        description: errorText
      });
    }
  }
};

export default successHandler;
