export const RESET_STATE = 'ROLES_RESET_STATE';
export const CURRENT_ITEM = 'ROLES_CURRENT_ITEM';

export const REQUEST_LOADING = 'ROLES_REQUEST_LOADING';
export const REQUEST_SUCCESS = 'ROLES_REQUEST_SUCCESS';
export const REQUEST_FAILED = 'ROLES_REQUEST_FAILED';

export const CURRENT_ACTION = 'ROLES_CURRENT_ACTION';
export const RESET_ACTION = 'ROLES_RESET_ACTION';

export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';

export const UPDATE_PAGINATION = 'ROLES_UPDATE_PAGINATION'; 

export const RESET_LIST = 'ROLES_RESET_LIST';

export const UPDATE_FILTER = 'ROLES_UPDATE_FILTER';

export const SET_FILTER_CHANGED_FLAG = 'SET_FILTER_CHANGED_FLAG';