import { AddUserProps } from '../components/UI/tab/types';
import { API_BASE_URL, AUTH_BASE_URL } from '../config/serverApiConfig';
import errorHandler from '../request/errorHandler';
import successHandler from '../request/successHandler';
import { api } from './api';
import axios, { AxiosError } from 'axios';

export const addUser = async (userData: AddUserProps, files: FileList | null) => {
  try {
    const formData = new FormData();

    const response = await api().post(`${API_BASE_URL}user/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const getRoleList = async () => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    const response = await api().get(
      API_BASE_URL + 'roles/list',
      {
        headers: {
          Authorization: `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const createRole = async (roleData: any) => {
  try {
    const response = await api().post(`${API_BASE_URL}role/create`, roleData);

    // Show notifications based on the response (if needed)
    successHandler(response, {
      notifyOnSuccess: true,
      notifyOnFailed: true
    });

    return {
      success: true,
      data: response.data,
      message: response.data?.message || 'Role created successfully'
    };
  } catch (error) {
    return errorHandler(error);
  }
};

export const editRole = async (roleData: any) => {
  try {
    const response = await api().put(`${API_BASE_URL}role/edit`, roleData);

    // Show notifications based on the response (if needed)
    successHandler(response, {
      notifyOnSuccess: true,
      notifyOnFailed: true
    });

    return {
      success: true,
      data: response.data,
      message: response.data?.message || 'Role created successfully'
    };
  } catch (error) {
    return errorHandler(error);
  }
};

export const deleteRole = async (roleData: any) => {
  try {
    const response = await api().delete(`${API_BASE_URL}role`, {
      data: {
        role: roleData
      }
    });

    // Show notifications based on the response (if needed)
    successHandler(response, {
      notifyOnSuccess: true,
      notifyOnFailed: true
    });

    return {
      success: true,
      data: response.data,
      message: response.data?.message || 'Role deleted successfully'
    };
  } catch (error) {
    return errorHandler(error);
  }
};
export const getPermissionList = async () => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    const response = await api().get(
      API_BASE_URL + 'permissions/list',
      {
        headers: {
          Authorization: `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return data.permissions;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getRoleDetail = async (name: string) => {
  try {

    const response = await api().get(
      API_BASE_URL + 'role/details',
      {
        headers: {
          'X-Role-Name': name
        }
      }
    );

    const { status, data } = response;

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const createUser = async (userData: any) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};
    const response = await api().post(
      API_BASE_URL + 'workspaces/assign-user',
      userData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return data.message;  // Return the success message from the backend
  } catch (error) {
    return errorHandler(error);  // Handle the error
  }
};

export const deleteUsers = async (ids: any[]) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    // Perform the DELETE request with a list of IDs in the request body
    const response = await axios.delete(
      `${AUTH_BASE_URL}users`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        },
        data: {
          ids: ids  // Send the list of IDs in the request body
        }
      }
    );

    const { status, data } = response;

    // Handle the success response
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );

    return data.message;  // Return the success message from the backend
  } catch (error) {
    // Handle the error response
    return errorHandler(error);
  }
};

const getList = async ({ page, items, sorting, globalFilter, filter, roles }: { page: number; items: number; sorting: string; globalFilter: string; filter?: any, roles?: string[] }) => {
  try {

    let url = `${API_BASE_URL}workspace/users?page=${page}&total=${items}`;
    if (sorting) {
      url += `&sorting=${JSON.stringify(sorting)}`;
    }
    if (globalFilter) {
      url += `&q=${globalFilter}`;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    if (roles) {
      url += `&roles=${roles.join(',')}`;
    }

    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};
    const response = await api().get(
      url,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getUserDetails = async (id: any) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    // Perform the DELETE request with a list of IDs in the request body
    const response = await api().get(
      `${API_BASE_URL}user/details`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`,
          'X-Keycloak-User-Id': id
        }
      }
    );

    const { status, data } = response;

    // Handle the success response
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return data;  // Return the success message from the backend
  } catch (error) {
    // Handle the error response
    return errorHandler(error);
  }
};

export const updateUser = async (id: string, dataItem: any) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    // Perform the DELETE request with a list of IDs in the request body
    const response = await axios.put(
      `${AUTH_BASE_URL}user/${id}`,
      dataItem,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;

    // Handle the success response
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return data.database_info;  // Return the success message from the backend
  } catch (error) {
    // Handle the error response
    return errorHandler(error);
  }
};

export const updateUserRole = async (id: string, dataItem: any) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    // Perform the DELETE request with a list of IDs in the request body
    const response = await api().put(
      `${API_BASE_URL}user/edit-roles`,
      dataItem,
      {
        headers: {
          'X-Keycloak-User-Id': id
        }
      }
    );

    const { status, data } = response;

    // Handle the success response
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );

    return data.database_info;  // Return the success message from the backend
  } catch (error) {
    // Handle the error response
    return errorHandler(error);
  }
};

export const updatePassword = async (id: string, password: any) => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : {};

    // Perform the DELETE request with a list of IDs in the request body
    const response = await axios.put(
      `${AUTH_BASE_URL}user/${id}`,
      {
        password
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tmpAuth.access_token}`
        }
      }
    );

    const { status, data } = response;

    // Handle the success response
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );

    return data.database_info;  // Return the success message from the backend
  } catch (error) {
    // Handle the error response
    return errorHandler(error);
  }
};

export const getUserByMail = async (email: string) => {
  try {
    const user = window.localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : null;
    const response = await axios.get(AUTH_BASE_URL + 'users-keycloak/email', {
      headers: {
        Authorization: `Bearer ${tmpAuth.access_token}`,
        'X-Email': email
      }
    });
    if (!response || !response.data) {
      throw new Error('Get me API response is invalid.');
    }
    return {
      data: response.data,
      status: 200
    };
  } catch (error: any) {
    const status = error.response.status;
    if (status === 500) {
      return {
        data: null,
        status: 404
      };
    }
    return errorHandler(error);
  }
};

export const tokenCheck = async (token: string) => {
  try {
    const response = await api().get(API_BASE_URL + 'invitation/verify/' + token, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (!response || !response.data) {
      throw new Error('Get me API response is invalid.');
    }
    return {
      data: response.data,
      status: 200,
      success: true
    };
  } catch (error: any) {
    const status = error.response.status;
    if (status === 500) {
      return {
        data: null,
        status: 404,
        success: false
      };
    }
    return errorHandler(error);
  }
};

export const confirmRegisterAccount = async (token: string, email: string, first_name: string, last_name: string, password: string, confirm_password: string) => {
  try {
    const response = await axios.post(API_BASE_URL + 'invitation/update-profile', {
      token: token,
      email: email,
      first_name: first_name,
      last_name: last_name,
      password: password,
      confirm_password: confirm_password
    });
    if (!response || !response.data) {
      throw new Error('Get me API response is invalid.');
    }
    return {
      data: response.data,
      status: 200,
      success: true
    };
  } catch (error: any) {
    const status = error.response.status;
    if (status === 500) {
      return {
        data: null,
        status: 404,
        success: false
      };
    }
    return errorHandler(error);
  }
};

export const resetInvitation = async (email: string) => {
  try {
    const response = await axios.post(API_BASE_URL + 'users/reset-invite', {
      email: email
    });
    if (!response || !response.data) {
      throw new Error('Get me API response is invalid.');
    }
    return {
      data: response.data,
      status: 200,
      success: true
    };
  } catch (error: any) {
    const status = error.response.status;
    if (status === 500) {
      return {
        data: null,
        status: 404,
        success: false
      };
    }
    return errorHandler(error);
  }
};

export const resetPassword = async (token: string, password: string, confirm_password: string) => {
  try {
    const response = await axios.post(API_BASE_URL + 'users/reset-password', {
      token,
      password,
      confirm_password
    });
    if (!response || !response.data) {
      throw new Error('Get me API response is invalid.');
    }
    return {
      data: response.data,
      status: 200,
      success: true
    };
  } catch (error: any) {
    const status = error.response.status;
    if (status === 500) {
      return {
        data: null,
        status: 404,
        success: false
      };
    }
    return errorHandler(error);
  }
};

export default {
  getRoleList,
  getPermissionList,
  getList,
  deleteUsers,
  getUserDetails,
  updateUser,
  createRole,
  getUserByMail,
  tokenCheck,
  confirmRegisterAccount,
  resetInvitation,
  resetPassword,
  getRoleDetail,
  updateUserRole,
  editRole,
  deleteRole
};