import {
  generatePieChartImageURL
} from '../../components/features/report/create/PieChartToImage';
import { getAssetNames } from '../../service/asset.service';
import { 
  getVulneStatsByProject, 
  reportList 
} from '../../service/report.service';
import { getVulnerabilities } from '../../service/vulnerability.service';
import * as actionTypes from './types';
import axios from 'axios';
// import { request } from '@/request';

export const report = {
  resetState:
    (props = {}) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.RESET_STATE
        });
      },
  resetAction:
    ({ actionType }) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.RESET_ACTION,
          keyState: actionType,
          payload: null
        });
      },
  currentItem:
    ({ data }) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: { ...data }
        });
      },
  currentAction:
    ({ actionType, data }) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.CURRENT_ACTION,
          keyState: actionType,
          payload: { ...data }
        });
      },
  handleUpdateReportDirect: (listName, index, field, value) => (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_REPORT_DATA_LIST,
      payload: {
        key: listName,
        index: index,
        field: field,
        value: value
      }
    });
  },
  removeListItem: (listName, index) => (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_LIST_ITEM,
      payload: { key: listName, index }
    });
  },
  addListItem: (listName) => (dispatch) => {
    dispatch({
      type: actionTypes.ADD_LIST_ITEM,
      payload: { key: listName }
    });
  },
  updateReportFieldObject: (objectName, field, value) => (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_REPORT_DATA_OBJECT,
      payload: { objectName, field, value }
    });
  },
  updateReportField:
    (key, value, getStats) =>
      async (dispatch, getState) => {
        await dispatch({
          type: actionTypes.UPDATE_REPORT_DATA,
          payload: { [key]: value }
        });
        const { reportData } = getState().report;
        if (getStats && reportData.project) {
          let responseAsset;
          if (key === 'project') {
            responseAsset = await getAssetNames(undefined, undefined, reportData.project);

            dispatch({
              type: actionTypes.UPDATE_REPORT_DATA,
              payload: {
                assetList: responseAsset.assets,
                assets: []
              }
            });
          }
          let response;
          if (reportData.vuln_filter === 1) { // get vuln if no filter
            response = await getVulneStatsByProject(reportData.project);
          } else {
            response = await getVulneStatsByProject(reportData.project, {
              assets: reportData.assets,
              status: reportData.status,
              severity: reportData.severity,
              confirmed: reportData.confirmed,
              tags: reportData.tags
            });
          }
          const { total_vulnerabilities, total_assets } = response.data.stats;
          dispatch({
            type: actionTypes.UPDATE_REPORT_DATA,
            payload: {
              total_vulnerabilities,
              total_assets
            }
          });
        }
      },
  fetchReports: (page, pageSize) => async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_REPORTS_REQUEST });
      
    try {
      const response = await reportList(page, pageSize);
      
      dispatch({
        type: actionTypes.FETCH_REPORTS_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: actionTypes.FETCH_REPORTS_FAILURE,
        error: error.message
      });
    }
  }
};
