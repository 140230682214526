import axios, { AxiosError } from 'axios';
import { API_BASE_URL } from '../config/serverApiConfig';
import errorHandler from '../request/errorHandler';
import successHandler from '../request/successHandler';
import { api } from './api';

export const createWorkspace = async (name: string, description: string) => {
  try {
    const url = `${API_BASE_URL}workspace/create`;
    const response = await api().post(url, {
      name,
      description
    });
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response.data;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

// Get Workspace list by user
export const getWorkspaceList = async (q?: string) => {
  try {
    const url = `${API_BASE_URL}workspaces/list?q=${q}`;
    const response = await api().get(url);
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response.data;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const selectWorkspace = async (workspaceId: string) => {
  try {
    const tmpAuth = window.localStorage.getItem('auth');
    const tmpAuthObj = tmpAuth ? JSON.parse(tmpAuth).current : {};

    const url = `${API_BASE_URL}workspace/active`;
    const response = await api().post(url, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-workspace-id': workspaceId
      }
    });
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response.data;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export default {
  createWorkspace,
  getWorkspaceList,
  selectWorkspace
};
