import CreateWorkspaceFeature from '../../components/features/workspaces/CreateWorkspaceFeature';
import ListWorkspaceFeature from '../../components/features/workspaces/ListWorkspaceFeature';

const ListWorkspace = () => (
  <>
    <div className="flex flex-col gap-10 h-full">
      <ListWorkspaceFeature />
    </div>
  </>
);

export default ListWorkspace;
