import {
  Layers,
  Check
} from 'lucide-react';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Table,
  Tabs,
  Input,
  Dropdown,
  ConfigProvider,
  Typography,
  Spin
} from 'antd';
import workspaceService from '../../../service/workspace.service';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuth,
  selectCurrentAdmin,
  selectCurrentWorkspace
} from '../../../redux/auth/selectors';
import { useNavigate } from 'react-router-dom';
import { updateDefaultWorkspace } from '../../../redux/auth/actions';
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  DownOutlined,
  MoreOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { MdWorkspaces } from 'react-icons/md';
import { IconBaseProps } from 'react-icons/lib';
const SafeMdWorkspaces = MdWorkspaces as React.FC<IconBaseProps>;

const { Search } = Input;

interface DataType {
  key: React.Key;
  name: string;
  id: string;
}

function WorkSpacesButton({ expanded }: any) {
  const ws = useSelector(selectCurrentWorkspace);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentAdmin);
  const [spinning, setSpinning] = React.useState(false);
  const [percent, setPercent] = React.useState(0);

  // Show Modal
  const showModal = () => setIsModalOpen(true);

  // Close Modal
  const handleCancel = () => setIsModalOpen(false);

  // Fetch Workspaces
  const fetchWorkspaces = async (query = '') => {
    try {
      setLoading(true);
      const res = await workspaceService.getWorkspaceList(query);
      setDataSource(res.workspaces || []);
    } catch (error) {
      console.error('Failed to fetch workspaces:', error);
    } finally {
      setLoading(false);
    }
  };

  // Initial Load
  useEffect(() => {
    fetchWorkspaces();
  }, []);

  const dispatch = useDispatch();

  // Columns for the Table
  const columns: any = [
    {
      title: '',
      dataIndex: '',
      width: 60,
      render: (text: any, record: any, i: any) => (
        <div className="flex justify-end items-center gap-2">
          {record?.id === ws?.id ? <Check
            size={14}
            color="blue"
          /> : <div
            className="h-3"
          ></div>}
          {/* {i === 2 ? <Star size={13} /> : <StarOff size={13} />} */}
          <SafeMdWorkspaces size={15} />
        </div>
      )
    }, {
      title: 'Name',
      dataIndex: 'name',
      width: 300,
      className: 'text-sm',
      render: (text: any, record: any) => (
        <div
          className='text-md'
          onClick={async () => {
            handleCancel();
            const res = await workspaceService.selectWorkspace(record.id);

            if (res.success !== false) {

              dispatch<any>(updateDefaultWorkspace(record)).then(() => {
                window.location.reload();
              });

            }
          }}
        >
          <a className="text-blue-700 underline">{text}</a>
        </div>
      )
    }, {
      title: 'ID',
      dataIndex: 'id',
      render: (text: any) => <Typography.Text
        type='secondary'
        className='text-sm'
      >{text}</Typography.Text>
    }
  ];

  return (
    <>
      <div className="flex items-center w-full justify-center">
        <Button
          type="link"
          className={`${expanded ? 'w-[80%]' : 'w-[50%]'} cursor-pointer !text-white bg-[#2e3038] hover:!bg-[#3a3b42] hover:text-white mx-4 border-[0.3px] border-whiten mt-5`}
          icon={<SafeMdWorkspaces size={18} className='font-bold' />}
          onClick={showModal}
        >
          {expanded ? ws?.name : ''}
        </Button>

        <Modal
          title="Select a Workspace"
          open={isModalOpen}
          onClose={handleCancel}
          closable={true}
          onCancel={handleCancel}
          onOk={handleCancel}
          okText="Cancel"
          okType="text"
          cancelButtonProps={{ hidden: true }}
          centered
          width={700}
          forceRender
        >
          <Search
            placeholder="Search a workspace"
            className="mt-5"
            type='default'
            loading={loading}
            onSearch={fetchWorkspaces}
          />

          <Tabs
            defaultActiveKey="1"
            className="mt-5"
            size="small"
            tabBarExtraContent={
              <Dropdown
                placement="bottomRight"
                menu={{
                  items: [
                    {
                      icon: <AppstoreOutlined />,
                      label: (
                        <a
                          rel="noopener noreferrer"
                          href="/workspaces"
                        >
                          See all workspaces
                        </a>
                      ),
                      key: '0'
                    }, {
                      icon: <AppstoreAddOutlined />,
                      disabled: !currentUser.admin_kc,
                      label: (
                        <a

                          rel="noopener noreferrer"
                          href="/workspace/create"
                        >
                          New Workspace
                        </a>
                      ),
                      key: '1'
                    }
                  ]
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Button
                    type="default"
                    size='small'
                    shape="circle"
                    icon={<MoreOutlined
                      size={30}
                      className='text-black font-bold text-xl'
                      height={30}
                      width={30}
                    />}
                  />
                </a>
              </Dropdown>
            }
            items={[
              {
                key: '1',
                label: 'Recent',
                children: (
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 0,
                        margin: 0
                      }
                    }}
                  >
                    <Table<any>
                      columns={columns}
                      onRow={(record) => ({
                        onClick: async () => {
                          setSpinning(true);
                          handleCancel();
                          const res = await workspaceService.selectWorkspace(record.id);

                          if (res.success !== false) {

                            await dispatch<any>(updateDefaultWorkspace(record)).then(() => {
                              window.location.reload();
                            });

                          }
                          setSpinning(false);
                        }
                      })}
                      dataSource={dataSource}
                      rowClassName={() => 'cursor-pointer !p-10 !m-10 text-sm'}
                      pagination={false}
                      size="small"
                      scroll={{ y: 55 * 5 }}
                    />
                  </ConfigProvider>
                )
              }
            ]}
          // items={[
          //   {
          //     key: '1',
          //     label: 'Recent',
          //     children: (
          //       <Table<any>
          //         columns={columns}
          //         dataSource={dataSource}
          //         pagination={false}
          //         bordered
          //         size="small"
          //         scroll={{ y: 55 * 5 }}
          //       />
          //     )
          //   }, {
          //     key: '2',
          //     label: 'Starred',
          //     children: (
          //       <Table<any>
          //         columns={columns}
          //         dataSource={dataSource.filter((item: any) => item.starred)}
          //         pagination={false}
          //         size="small"
          //         scroll={{ y: 55 * 5 }}
          //       />
          //     )
          //   }, {
          //     key: '3',
          //     label: 'All',
          //     children: (
          //       <Table<any>
          //         columns={columns}
          //         dataSource={dataSource}
          //         pagination={false}
          //         size="small"
          //         scroll={{ y: 55 * 5 }}
          //       />
          //     )
          //   }
          // ]}
          />
        </Modal>
      </div>
      <Spin spinning={spinning} percent={'auto'} fullscreen />

    </>
  );
}

export default WorkSpacesButton;