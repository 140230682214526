import { Suspense, useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@material-tailwind/react';
import { App as AntdApp } from 'antd';

import store from './redux/store';
import AppContent from './AppContent';
import Loading from './components/UI/loading/Loading';
import { fetchUser } from './redux/auth/actions';
import { selectCurrentAdmin, selectIsLoggedIn } from './redux/auth/selectors';
import { logout } from './service/auth';

function AppInitializer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const current = useSelector(selectCurrentAdmin);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const { pathname } = window.location;

    if (!pathname.startsWith('/auth/')) {
      const auth = localStorage.getItem('auth');
      if (auth) {
        dispatch<any>((dispatch: any) => {
          dispatch(fetchUser()).finally((data: any) => {
            console.log('----------- current ------- ');
            console.log(current);
            if (!current?.default_workspace?.id) {
              console.log('------------- data ---------');
              console.log(data);
              console.log('User is logged');
              navigate('/workspaces');
            }
            ;
          });
        });
      } else {
        logout();
        navigate('/login');
        console.log('User is not logged in and token is not present, so go back to the login page');
      }
    }
    setInitializing(false);
  }, [dispatch]);

  if (initializing) return <Loading />;

  return <AppContent />;
}

function App() {
  return (
    <Provider store={store}>
      <AntdApp>
        <ThemeProvider>
          <AppInitializer />
        </ThemeProvider>
      </AntdApp>
    </Provider>
  );
}

export default App;
